<template>
  <vz-dialog
    :big="!isShort"
    :small="isShort"
    public
    name="feedback"
    title="Обратная связь"
    :full-screen="isFullScreen"
    class="vz-feedback-call-modal"
    @show="onShow"
    hide-footer
  >
    <vz-feedback-form :preset="presetData" />
  </vz-dialog>
</template>

<script setup lang="ts">
import $breackpoints from '~/composables/breakpoints'
import { VzDialogPreset } from '~~/types/types'
import VzDialog from '~/components/ui-kit/vz-dialog.vue';
import {defineAsyncComponent} from 'vue'

const VzFeedbackForm = defineAsyncComponent(() => import('~/components/forms/vz-feedback-form.vue'))

const { $showDialog } = useNuxtApp();
const presetData = ref({} as VzDialogPreset)

const isShort = computed(() => {
  return !!presetData.value?.isShort
})

const isFullScreen = computed((): Boolean => {
  return $breackpoints?.isPublicXS
})

function onShow(preset: VzDialogPreset) {
  presetData.value = preset
}

onMounted(() => {
  if(useRoute().query.contactType){
    $showDialog({ name: 'feedback' })
  }
})
</script>
